import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";
import classNames from "classnames";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				category: "экосистема",
				title: "Никакой путаницы. Всё в одном\nсервисе",
				tabletTitle: "Никакой путаницы. Всё в одном сервисе",
				mobileTitle: "Никакой путаницы. Всё в одном\nсервисе",
				description:
					"<p>Принимай заказы из&nbsp;зала, на&nbsp;самовывоз и доставку в одном интерфейсе.</p>" +
					"<p>Управляй меню,&nbsp;доступностью позиций и стоп-листами. Всё обновляется автоматически на&nbsp;всех ресурсах: приложение, агрегаторы, сайт. Теперь не&nbsp;придётся проверять разные устройства и&nbsp;открывать множество вкладок.</p>",
				img: (
					<StaticImage
						className={classNames(styles.image1, styles.image1__kz)}
						src="./assets/feature-1_kz.png"
						alt="кассовые программы для ресторанов"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.featureContainer,
			},
			{
				category: "допродажи",
				title: "Предлагай больше, \nувеличивай чек",
				description: (
					<div className={styles.smallDescription}>
						<p className={styles.smallDescription}>
							Хотите попробовать на&nbsp;кокосовом молоке? Подсказки
							на&nbsp;терминале напомнят официанту предложить топпинги
							и&nbsp;модификаторы. Так можно точнее попасть в&nbsp;предпочтения
							гостя и&nbsp;не&nbsp;упустить дополнительную прибыль.
						</p>
					</div>
				),
				img: (
					<StaticImage
						className={styles.image2}
						src="./assets/feature-2_kz.png"
						alt="кассовый терминал для кафе"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				reverse: true,
				className: styles.reverseFeatureContainer,
			},
			{
				category: "безопасность",
				title: "Доверь кассу персоналу",
				description:
					"<p>Доверие к&nbsp;новому персоналу складывается постепенно. Ограничение прав доступа к&nbsp;действию на&nbsp;кассе и&nbsp;моментальные оповещения о&nbsp;сомнительных операциях защитят бизнес от&nbsp;воровства.</p>",
				img: (
					<StaticImage
						className={styles.image3}
						src="./assets/feature-3_kz.png"
						alt="кассовая программа для общепита"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.featureContainer,
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				category: "экожүйе",
				title: "Ешқандай былық жоқ. Бәрі бір сервисте",
				tabletTitle: "Ешқандай былық жоқ. Бәрі бір сервисте",
				mobileTitle: "Ешқандай былық жоқ. Бәрі бір сервисте",
				description:
					"<p>Бір интерфейсте залдан, өзімен әкетуге және жеткізуге тапсырыстарды қабылдаңыз.</p>" +
					"<p>Мәзірді, позициялар қолжетімділігін және стоп-листтерді басқарыңыз. Бәрі барлық ресурстарда автоматты түрде жаңартылады: қосымша, агрегаторлар, сайт. Енді түрлі құрылғыларды тексерудің және көптеген қойындыларды ашудың керегі жоқ.</p>",
				img: (
					<StaticImage
						className={classNames(styles.image1, styles.image1__kz)}
						src="./assets/feature-1_kz.png"
						alt="кассовые программы для ресторанов"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.featureContainer,
			},
			{
				category: "қосымша сату",
				title: "Көбірек ұсыныңыз,\nчекті ұлғайтыңыз",
				description: (
					<div className={styles.smallDescription}>
						<p className={styles.smallDescription}>
							Кокос сүтімен ішіп көргіңіз келе ме?  Терминалдағы сыбырлар топпингтер мен модификаторларды
							ұсынуды даяшының есіне салады. Қосымша пайданы жіберіп алмай,
							қонақтың қалауларын қалай дәлірек анықтауға болады.
						</p>
					</div>
				),
				img: (
					<StaticImage
						className={styles.image2}
						src="./assets/feature-2_kz.png"
						alt="кассовый терминал для кафе"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				reverse: true,
				className: styles.reverseFeatureContainer,
			},
			{
				category: "қауіпсіздік",
				title: "Кассаны персоналға сеніп тапсырыңыз",
				description:
					"<p>Жаңа персоналған сенім біртіндеп қалыптасады. Кассадағы әрекетке қол жеткізу құқықтарын шектеу және күмәнді операциялар туралы лезде хабарлау бизнесті ұрлықтан қорғайды.</p>",
				img: (
					<StaticImage
						className={styles.image3}
						src="./assets/feature-3_kz.png"
						alt="кассовая программа для общепита"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.featureContainer,
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				category: "экосистема",
				title: "Все решения \nв одном сервисе",
				tabletTitle: "Все решения в одном сервисе",
				mobileTitle: "Все решения \nв одном сервисе",
				description:
					"<p>Принимай заказы из&nbsp;зала, на&nbsp;самовывоз и доставку в&nbsp;одном интерфейсе.</p>" +
					"<p>Управляй меню и доступностью позиций, включай стоп&nbsp;листы. Меню обновляется автоматически на&nbsp;всех ресурсах: приложение, агрегаторы, сайт. Теперь не&nbsp;придется проверять разные устройства и&nbsp;открывать множество вкладок.</p>",
				img: (
					<StaticImage
						className={classNames(styles.image1, styles.image1__by)}
						src="./assets/feature-1_by_new.png"
						alt="кассовые программы для ресторанов"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.featureContainer,
			},
			{
				category: "допродажи",
				title: "Увеличивай \nсредний чек",
				description: (
					<div className={styles.smallDescription}>
						<p className={styles.smallDescription}>
							Хотите попробовать на&nbsp;кокосовом молоке? Подсказки
							на&nbsp;терминале напомнят официанту предложить топпинги и&nbsp;
							модификаторы. Так можно точнее попасть в&nbsp;предпочтения
							гостя и&nbsp;не&nbsp;упустить дополнительную прибыль.
						</p>
					</div>
				),
				img: (
					<StaticImage
						className={styles.image2}
						src="./assets/feature-2_by.png"
						alt="кассовый терминал для кафе"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				reverse: true,
				className: styles.reverseFeatureContainer,
			},
			{
				category: "безопасность",
				title: "Доверь кассу персоналу",
				description:
					"<p>Доверие к&nbsp;новому персоналу складывается постепенно. Ограничение прав доступа к&nbsp;действию на&nbsp;кассе и&nbsp;моментальные оповещения о&nbsp;сомнительных операциях защитят бизнес от&nbsp;воровства.</p>",
				img: (
					<StaticImage
						className={styles.image3}
						src="./assets/feature-3_by.png"
						alt="кассовая программа для общепита"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				className: styles.featureContainer,
			},
		]
	}

	return [
		{
			category: "экосистема",
			title: "Один для всех и всё в одном",
			tabletTitle: "Один для всех \nи всё в одном",
			description:
				"<p>Принимай заказы из&nbsp;зала, на&nbsp;самовывоз, доставку, деливери и&nbsp;яндекс в&nbsp;интерфейсе одной кассовой программы для общепита.</p>" +
				"<p>Управляй меню и&nbsp;доступностью позиций, включай стоп листы. Меню обновляется автоматически на&nbsp;всех ресурсах: приложение, агрегаторы, сайт. Теперь не&nbsp;придется проверять разные устройства и&nbsp;открывать множество вкладок.</p>",
			img: (
				<StaticImage
					className={classNames(styles.image1, styles.image1__ru)}
					src="./assets/feature-1_ru_022025.png"
					alt="кассовые программы для ресторанов"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			className: styles.featureContainer,
		},
		{
			category: "допродажи",
			title: "Не бойся \nпредложить больше",
			description: (
				<div className={styles.smallDescription}>
					<p className={styles.smallDescription}>
						Хотите попробовать на&nbsp;кокосовом молоке? Подсказки на&nbsp;кассовом терминале для кафе напомнят
						официанту предложить топпинги и&nbsp;модификаторы. Так можно точнее попасть в&nbsp;предпочтения
						гостя и&nbsp;не&nbsp;упустить дополнительную прибыль.
					</p>
				</div>
			),
			img: (
				<StaticImage
					className={styles.image2}
					src="./assets/feature-2.png"
					alt="кассовый терминал для кафе"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			reverse: true,
			className: styles.reverseFeatureContainer,
		},
		{
			category: "безопасность",
			title: "Доверь кассу персоналу",
			description:
				"<p>Доверие к&nbsp;новому персоналу складывается постепенно. Чтобы защитить бизнес от&nbsp;воровства, " +
				"в&nbsp;кассовых программах для ресторанов предусмотрены ограничение прав доступа и&nbsp;моментальные " +
				"оповещения о&nbsp;сомнительных операциях.</p>",
			img: (
				<StaticImage
					className={styles.image3}
					src="./assets/feature-3.png"
					alt="кассовая программа для общепита"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			className: styles.featureContainer,
		},
	]
}

